@font-face {
  font-family: 'Arcade';
  src: 
   /*url('myfont.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
   url('../ARCADECLASSIC.TTF') format('truetype'); /* Chrome 4+, Firefox 3.5, Opera 10+, Safari 3—5 */
} 

* {
  box-sizing: border-box;
}

body {
  font-family: 'Segoe UI'
}

table {
  width: 100%;
  border-collapse: collapse;
  font-family: 'Arcade';
}

table td,
table th {
  padding: .5em 1em;
  text-align: left;
}

table th {
  border-bottom: 1px solid #999;
}

table tr td:last-child {
  position: relative;
  white-space: nowrap;
}

.row-action {
  position: absolute;
  display: none;
  right: 1em;
  top: 50%;
  transform: translateY(-50%);
  background-color: #f4f4f4;
  padding: 0.25em 0.5em;
}

tr:hover .row-action {
  display: block;
}

i:not(:last-child) {
  margin-right: 1em;
}

.circle-img{
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.trophy{
  position: relative;
  color:rgb(204, 180, 46);
  border-color: black;
  box-shadow: saddlebrown;
}

.badge {  
  position: absolute;
  top: -18px;  
  padding: 4px 8px;
  margin-top: 0px;
  border-radius: 50%;
  font-size: 10px;
  text-align: center;
  background: #b39c2a;
  color: #fefefe;
}